
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient } from '../types';

@Component
export default class CustomerOrtoTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  @Prop({ default: () => { } })
  readonly caption: string | undefined;

  @Prop({ default: () => { } })
  readonly imagePadding: string | undefined;

  @Prop({ default: () => { } })
  readonly image: string | undefined;
}
